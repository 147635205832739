
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppCommonUser} from "@/models/app/common/User";
import {AppCommonTrainingCategory} from "@/models/app/common/training-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachTrainingsNavigationVue from "@/components/coach/trainings/navigation.vue";
import AppCoachTrainingsRootFilterIndexVue from "@/views/app/coach/trainings/root/filter/index.vue";
import AppCoachTrainingsRootListIndexVue from "@/views/app/coach/trainings/root/list/index.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachTrainingsNavigationVue,
    AppCoachTrainingsRootFilterIndexVue,
    AppCoachTrainingsRootListIndexVue,
  },
})
export default class AppCoachTrainingsRootIndexVue extends Vue {
  isLoading: boolean | null = false;

  players: Array<AppCommonUser> = [];
  trainingCategories: Array<AppCommonTrainingCategory> = [];

  $refs!: {
    AppCoachTrainingsRootListIndexVue: any;
  };

  getTrainingsByFilter(formData: any): void {
    this.$refs.AppCoachTrainingsRootListIndexVue.getTrainings(formData);
  }

  async getPlayers(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/organization-members`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        role: "player",
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.players = r.data;
      }
    });
    this.isLoading = false;
  }

  async getTrainingCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/training-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.trainingCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getPlayers();
    await this.getTrainingCategories();
  }
}
